import { render, staticRenderFns } from "./Recommendations.vue?vue&type=template&id=0511088c&scoped=true&"
import script from "./Recommendations.vue?vue&type=script&lang=js&"
export * from "./Recommendations.vue?vue&type=script&lang=js&"
import style0 from "./Recommendations.vue?vue&type=style&index=0&id=0511088c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0511088c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeSectionHeader: require('/var/www/html/preview/components/home/SectionHeader.vue').default,ContentfulImage: require('/var/www/html/preview/components/contentful/Image.vue').default,RemoveMarkdown: require('/var/www/html/preview/components/RemoveMarkdown.vue').default,DynamicLink: require('/var/www/html/preview/components/DynamicLink.vue').default,IconPrev: require('/var/www/html/preview/components/icons/IconPrev.vue').default,IconNext: require('/var/www/html/preview/components/icons/IconNext.vue').default})
